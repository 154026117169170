<template>
  <v-container
    v-if="error"
    class="d-flex flex-column justify-center align-center h-100 text-center"
  >
    <v-icon color="error" x-large>mdi-alert</v-icon>
    <div class="mt-2">
      {{ $t('fillSurvey.error') }}
    </div>
    <div class="d-flex justify-center mt-2">
      <v-btn @click="exit">
        {{ this.$t('fillSurvey.exit') }}
      </v-btn>
    </div>
  </v-container>
  <v-container fluid v-else-if="schema && !saving && !completed">
    <v-row>
      <v-col cols="12" class="d-flex justify-center">
        <logo-image />
      </v-col>
    </v-row>
    <survey-form :schema="schema" :templateId="formId" @newSubmission="onSubmit" />
  </v-container>
  <div
    style="position: fixed; top: 0; bottom: 0; right: 0; left: 0"
    class="d-flex flex-column justify-center align-center"
    v-else-if="saving || completed"
  >
    <div style="max-width: 500px" class="px-4">
      <logo-image />
      <v-progress-linear indeterminate class="mt-4" v-if="saving" />

      <div class="text-center mt-2 d-flex text-caption justify-center">
        <v-icon v-if="completed" color="success" class="mr-2">mdi-check-circle</v-icon>
        {{ completed ? this.$t('fillSurvey.submitted') : this.$t('fillSurvey.submitting') }}
      </div>
      <div v-if="completed" class="d-flex justify-center mt-2">
        <v-btn v-if="completed" text color="primary" @click="exit">
          {{ this.$t('fillSurvey.exit') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import to from 'await-to-js';
import f from '../utils/fetch';
import SurveyForm from '../components/SurveyForm.vue';
import LogoImage from '../components/LogoImage.vue';

export default {
  data() {
    return {
      error: false,
      template: null,
      schema: null,
      formId: null,
      loading: false,
      saving: false,
      completed: false,
    };
  },
  components: {
    LogoImage,
    SurveyForm,
  },
  computed: {
    providerId() {
      return this.$route.query.providerId;
    },
    memberId() {
      return this.$route.query.memberId;
    },
    templateId() {
      return this.$route.query.id;
    },
  },
  mounted() {
    this.loadSurvey();
  },
  methods: {
    exit() {
      window.open('https://www.sentryhealth.ca');
    },
    async loadSurvey() {
      this.loading = true;
      if (!this.providerId || !this.templateId) {
        this.error = true;
        return;
      }

      const [error, template] = await to(
        f(`providers/${this.providerId}/publicTemplates/${this.templateId}`)
      );

      if (!error) {
        this.template = template;
        this.schema = template.schema;
        this.formId = template.id;
      } else {
        this.error = true;
      }

      this.loading = false;
    },
    async onSubmit(body) {
      this.saving = true;
      try {
        const requestBody = body;
        if (this.memberId) requestBody.memberId = this.memberId;
        await f.post({
          route: `providers/${this.providerId}/publicForms`,
          body: requestBody,
        });
        this.completed = true;
      } catch (e) {
        console.error(e);
      }
      this.saving = false;
    },
  },
};
</script>
